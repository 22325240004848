let FirebaseConfig = {
  apiKey: "AIzaSyCWUU26MBZUUGj3l-XHlrdS-m0vhoEfOw0",
  authDomain: "fracnote-e35c3.firebaseapp.com",
  databaseURL: "https://fracnote-e35c3.firebaseio.com",
  projectId: "fracnote-e35c3",
  storageBucket: "fracnote-e35c3.appspot.com",
  messagingSenderId: "914574198992",
  appId: "1:914574198992:web:8ec931c87727fec66236d5",
  measurementId: "G-KPQS326Z1D",
};

export default FirebaseConfig;
